jQuery( document ).ready( function( $ ) {
	$( document ).on( 'change', '.quantity .qty', function() {
		$(this).parents('td').next('td').find('.add_to_cart_button').attr('data-quantity', $(this).val());
		$(this).parents('td').next('td').find('.qty_hidden').val($(this).val());
	});
	
	$(document).on('change', '#pro_dd_singlepg', function(){
		$('.dd_change_effect').addClass('d-none');
		var p_id = $(this).val();
		
		//$('#btn_atc_'+p_id).attr('data-quantity','1');
		$('#btn_atc_'+p_id).removeClass('d-none');
		$('#product_price_'+p_id).removeClass('d-none');
		$('#pro_vari_tbl_'+p_id).removeClass('d-none');
		//console.log(p_id);
	});


	jQuery(document).on('click','.go-to-billing', function(){
		$('#payment').addClass('d-none');
		$('.woocommerce-additional-fields__field-wrapper').removeClass('d-none');
		$('.go-to-payment').removeClass('d-none');
		$('.woocommerce-shipping-fields').removeClass('d-none');
		$('.woocommerce-billing-fields').removeClass('d-none');
		$('.go-to-cart').removeClass('d-none');
		$('.op_block_title').html('Checkout');
		$('.addi_info').removeClass('d-none');
		$('.op_main_page').removeClass('op_payment_page');
	});
    jQuery(document).on('click', '.go-to-payment ', function(e){
    	$('#payment').addClass('d-none');
    	var fname = $('#billing_first_name').val();
    	var lname = $('#billing_last_name').val();
    	var phone = $('#billing_phone').val();
    	var email = $('#billing_email').val();
    	var country = $('#billing_country').val();
    	var city = $('#billing_city').val();
    	var zipcode = $('#billing_postcode').val();
    	var add1 = $('#billing_address_1').val();
    	var shipping;
    	if($('#ship-to-different-address-checkbox').is(":checked"))
    	{
    		shipping = true;
    		var s_fname = $('#shipping_first_name').val();
	    	var s_lname = $('#shipping_last_name').val();
	    	//var s_phone = $('#shipping_phone').val();
	    	//var s_email = $('#shipping_email').val();
	    	var s_country = $('#shipping_country').val();
	    	var s_city = $('#shipping_city').val();
	    	var s_zipcode = $('#shipping_postcode').val();
	    	var s_add1 = $('#shipping_address_1').val();
    	}
    	else
    	{
    		shipping = false;
    	}
    	var valid ;
    	if(fname == "")
    	{
    		valid = false;
    	}
    	else if(lname == "")
    	{
    		valid = false;
    	}
    	else if(phone == "")
    	{
    		valid = false;
    	}
    	else if(email == "")
    	{
    		valid = false;
    	}
    	else if(country == "")
    	{
    		valid = false;
    	}
    	else if(city == "")
    	{
    		valid = false;
    	}
    	else if(zipcode == "")
    	{
    		valid = false;
    	}
    	else if(add1 == "")
    	{
    		valid = false;
    	}
    	else
    	{
    		valid = true;
    	}
    	if(!valid)
    	{
    		$('.woocommerce-checkout').submit();

    	}
    	else
    	{
    		if(shipping)
    		{
    			if(s_fname == "")
		    	{
		    		valid = false;
		    	}
		    	else if(s_lname == "")
		    	{
		    		valid = false;
		    	}
		    	// else if(phone == "")
		    	// {
		    	// 	valid = false;
		    	// }
		    	// else if(email == "")
		    	// {
		    	// 	valid = false;
		    	// }
		    	else if(s_country == "")
		    	{
		    		valid = false;
		    	}
		    	else if(s_city == "")
		    	{
		    		valid = false;
		    	}
		    	else if(s_zipcode == "")
		    	{
		    		valid = false;
		    	}
		    	else if(s_add1 == "")
		    	{
		    		valid = false;
		    	}
		    	else
		    	{
		    		valid = true;
		    	}
		    	if(!valid)
		    	{
		    		$('.woocommerce-checkout').submit();
		    	}
		    	else
	    		{
	    			var full_add = s_add1+', '+s_country+', '+s_city+', '+s_zipcode;
	    			//alert('show Payment');
	    			$('#payment').removeClass('d-none');
	    			$('.op_payment_shipping_address').html('<span>Shipping Address : </span>' + full_add + '<a href="javascript:void" class="go-to-billing">Edit</a>');
	    			$('.woocommerce-additional-fields__field-wrapper').addClass('d-none');
	    			$('.go-to-payment').addClass('d-none');
	    			$('.woocommerce-shipping-fields').addClass('d-none');
	    			$('.woocommerce-billing-fields').addClass('d-none');
	    			$('.woocommerce-error').addClass('d-none');
	    			$('.go-to-cart').addClass('d-none');
	    			$('.op_block_title').html('Payment Method');
	    			$('.addi_info').addClass('d-none');
	    			$('.op_main_page').addClass('op_payment_page');
	    		}

    		}
    		else
    		{
    			//alert('show Payment');
    			var full_add = add1+', '+country+', '+city+', '+zipcode;
    			$('#payment').removeClass('d-none');
    			$('.op_payment_shipping_address').html('<span>Shipping Address : </span>' + full_add + '<a href="javascript:void" class="go-to-billing">Edit</a>');
    			$('.woocommerce-additional-fields__field-wrapper').addClass('d-none');
    			$('.go-to-payment').addClass('d-none');
    			$('.woocommerce-shipping-fields').addClass('d-none');
    			$('.woocommerce-billing-fields').addClass('d-none');
    			$('.woocommerce-error').addClass('d-none');
    			$('.go-to-cart').addClass('d-none');
    			$('.op_block_title').html('Payment Method');
    			$('.addi_info').addClass('d-none');
    			$('.op_main_page').addClass('op_payment_page');
    		}	
    	}

	});
	
});